import PropTypes from "prop-types";
// @mui
import { socket } from "src/App";
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, AppBar, Button, Toolbar, IconButton } from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER, NAV } from "../../../config-global";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";
//
import AccountPopover from "./AccountPopover";
import LevelPopover from "./LevelPopover";
import { useAuthContext } from "src/auth/useAuthContext";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/system";
import axios from "axios";
import { getRealTimeOrdersCount } from "src/redux/slices/ordersSlice";
import Label from "src/components/label";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const theme = useTheme();

  const dispatch = useDispatch();

  const { themeLayout } = useSettingsContext();

  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tableId = queryParams.get("table"); // Get the "table" parameter

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const isDesktop = useResponsive("up", "lg");

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const isCalendar = () =>
    pathname === "/dashboard/reservation/calendar" ? true : false;

  const isPOS = () => (pathname === "/dashboard/pos" ? true : false);

  const isTables = () => (pathname === "/dashboard/tables" ? true : false);

  const isOrders = () => (pathname === "/dashboard/orders" ? true : false);

  const isKitchenScreen = () =>
    pathname === "/dashboard/kitchen" ? true : false;

  const handleLevelChange = () => { };

  const { orderCount } = useSelector((state) => state.ordersSlice);

  const selectedSound = user?.notificationSound || "egoraSound1";

  useEffect(() => {
    const fetchOrderCounts = async () => {
      try {
        const getToken = localStorage.getItem("accessToken");
        await dispatch(
          getRealTimeOrdersCount(user?.venueId, user?.levelId, getToken)
        );
      } catch (error) {
        console.error("Failed to fetch order counts:", error);
      }
    };

    fetchOrderCounts();
  }, []);

  const notificationSounds = {
    egoraSound1: "/assets/sounds/notificationBeep.wav",
    egoraSound2: "/assets/sounds/mixkit-bell-notification-933.wav",
    egoraSound3: "/assets/sounds/Cute.mp3",
    egoraSound4: "/assets/sounds/Nice.mp3",
    egoraSound5: "/assets/sounds/Cute Magical Bell.mp3",
    egoraSound6: "/assets/sounds/loud.mp3",
    egoraSound7: "/assets/sounds/message.mp3",
    egoraSound8: "/assets/sounds/salesFault.mp3",
  };

  useEffect(() => {
    socket.on("processingOrdersForPOS", (data) => {
      if (
        (user?.venueId === data.venueId.id || user?.venueId === data.venueId) &&
        (user?.levelId === data.levelId ||
          user?.role == "admin" ||
          data.isSuperAdmin)
      ) {
        if (
          (user.venue?.configurations?.isOrderingFeatureAvailed ||
            user.venue?.configurations?.isKitchenFeatureAvailed) &&
          (user.permissions?.canSeeOrderProcessingScreen ||
            user.permissions?.canSeeKitchenScreen)
        ) {
          if (user?.permissions?.enableAlertSound) {
            const audio = new Audio(notificationSounds[selectedSound]);
            audio.play().catch((e) => console.error("Audio play failed:", e));
          }
          const getToken = localStorage.getItem("accessToken");
          dispatch(
            getRealTimeOrdersCount(user?.venueId, user?.levelId, getToken)
          );
        }
      }
    });

    socket.on("processingOrderClear", (data) => {
      if (
        (user?.venueId === data.venueId.id || user?.venueId === data.venueId) &&
        (user?.levelId === data.levelId ||
          user?.role == "admin" ||
          data.isSuperAdmin)
      ) {
        if (
          (user.venue?.configurations?.isPOSAvailable ||
            user.venue?.configurations?.isKitchenFeatureAvailed) &&
          (user.permissions?.canSeeOrderProcessingScreen ||
            user.permissions?.canSeeKitchenScreen)
        ) {
          const getToken = localStorage.getItem("accessToken");
          dispatch(
            getRealTimeOrdersCount(user?.venueId, user?.levelId, getToken)
          );
        }
      }
    });

    socket.on("OrderDeleted", (orderData) => {
      if (
        (user.venueId === orderData.venueId.id ||
          user.venueId === orderData.venueId) &&
        (user.levelId === orderData.levelId ||
          user.role == "admin" ||
          orderData.isSuperAdmin)
      ) {
        const getToken = localStorage.getItem("accessToken");
        dispatch(
          getRealTimeOrdersCount(user?.venueId, user?.levelId, getToken)
        );
      }
    });

    socket.on("updateOrder", (data) => {
      if (
        (user?.venueId === data.venueId.id || user?.venueId === data.venueId) &&
        (user?.levelId === data.levelId ||
          user?.role == "admin" ||
          data.isSuperAdmin)
      ) {
        if (
          (user.venue?.configurations?.isOrderingFeatureAvailed ||
            user.venue?.configurations?.isKitchenFeatureAvailed) &&
          (user.permissions?.canSeeOrderProcessingScreen ||
            user.permissions?.canSeeKitchenScreen)
        ) {
          const hasUnpreparedItems = data.items.some(
            (item) => !item.isPrepared
          );
          if (data.isPaid == false || hasUnpreparedItems) {
            if (user?.permissions?.enableAlertSound) {
              const audio = new Audio(notificationSounds[selectedSound]);
              audio.play().catch((e) => console.error("Audio play failed:", e));
            }
            const getToken = localStorage.getItem("accessToken");
            dispatch(
              getRealTimeOrdersCount(user?.venueId, user?.levelId, getToken)
            );
          }
        }
      }
    });

    socket.on("processingOrderClear", (data) => {
      if (
        (user?.venueId === data.venueId.id || user?.venueId === data.venueId) &&
        (user?.levelId === data.levelId ||
          user?.role == "admin" ||
          data.isSuperAdmin)
      ) {
        if (
          (user.venue?.configurations?.isOrderingFeatureAvailed ||
            user.venue?.configurations?.isKitchenFeatureAvailed) &&
          (user.permissions?.canSeeOrderProcessingScreen ||
            user.permissions?.canSeeKitchenScreen)
        ) {
          if (user?.permissions?.enableAlertSound) {
            const audio = new Audio(notificationSounds[selectedSound]);
            audio.play().catch((e) => console.error("Audio play failed:", e));
          }
        }
      }
    });

    socket.on("cancelOrder", (data) => {
      if (
        (user?.venueId === data.venueId.id || user?.venueId === data.venueId) &&
        (user?.levelId === data.levelId ||
          user?.role == "admin" ||
          data.isSuperAdmin)
      ) {
        if (
          (user.venue?.configurations?.isPOSAvailable ||
            user.venue?.configurations?.isKitchenFeatureAvailed) &&
          (user.permissions?.canSeeOrderProcessingScreen ||
            user.permissions?.canSeeKitchenScreen)
        ) {
          const getToken = localStorage.getItem("accessToken");
          dispatch(
            getRealTimeOrdersCount(user?.venueId, user?.levelId, getToken)
          );
        }
      }
    });

    socket.on("completedOrderRemove", (data) => {
      if (
        (user?.venueId === data.venueId.id || user?.venueId === data.venueId) &&
        (user?.levelId === data.levelId ||
          user?.role == "admin" ||
          data.isSuperAdmin)
      ) {
        if (
          (user.venue?.configurations?.isPOSAvailable ||
            user.venue?.configurations?.isKitchenFeatureAvailed) &&
          (user.permissions?.canSeeOrderProcessingScreen ||
            user.permissions?.canSeeKitchenScreen)
        ) {
          const getToken = localStorage.getItem("accessToken");
          dispatch(
            getRealTimeOrdersCount(user?.venueId, user?.levelId, getToken)
          );
        }
      }
    });

    socket.on("IncomingOrdersForPos", (data) => {
      if (
        (user?.venueId === data.venueId.id || user?.venueId === data.venueId) &&
        (user?.levelId === data.levelId ||
          user?.role == "admin" ||
          data.isSuperAdmin)
      ) {
        if (
          (user.venue?.configurations?.isOrderingFeatureAvailed ||
            user.venue?.configurations?.isKitchenFeatureAvailed) &&
          (user.permissions?.canSeeOrderProcessingScreen ||
            user.permissions?.canSeeKitchenScreen)
        ) {
          if (user?.permissions?.enableAlertSound) {
            const audio = new Audio(notificationSounds[selectedSound]);
            audio.play().catch((e) => console.error("Audio play failed:", e));
          }
          const getToken = localStorage.getItem("accessToken");
          dispatch(
            getRealTimeOrdersCount(user?.venueId, user?.levelId, getToken)
          );
        }
      }
    });

    socket.on("IncomingOrderCancel", (data) => {
      if (
        (user?.venueId === data.venueId.id || user?.venueId === data.venueId) &&
        (user?.levelId === data.levelId ||
          user?.role == "admin" ||
          data.isSuperAdmin)
      ) {
        if (
          (user.venue?.configurations?.isOrderingFeatureAvailed ||
            user.venue?.configurations?.isKitchenFeatureAvailed) &&
          (user.permissions?.canSeeOrderProcessingScreen ||
            user.permissions?.canSeeKitchenScreen)
        ) {
          if (user?.permissions?.enableAlertSound) {
            const audio = new Audio(notificationSounds[selectedSound]);
            audio.play().catch((e) => console.error("Audio play failed:", e));
          }
          const getToken = localStorage.getItem("accessToken");
          dispatch(
            getRealTimeOrdersCount(user?.venueId, user?.levelId, getToken)
          );
        }
      }
    });
  }, [socket]);

  const handleGoToPOS = () => {
    if (!tableId) {
      navigate(PATH_DASHBOARD.pos.list, { replace: true });
    }
  };

  const handleGoToReservations = () => {
    navigate(PATH_DASHBOARD.reservation.calendar, { replace: true });
  };

  const handleGoToTablesScreen = () => {
    navigate(PATH_DASHBOARD.tables.list, { replace: true });
  };

  const handleGoToKitchenScreen = () => {
    navigate(PATH_DASHBOARD.kitchen.list, { replace: true });
  };

  const handleGoToOrders = () => {
    navigate(PATH_DASHBOARD.orders.list, { replace: true });
  };

  const tabMappings = {
    pos: {
      condition:
        user?.venue?.configurations?.isPOSAvailable &&
        user.permissions?.canViewPos,
      path: "/dashboard/pos",
      action: handleGoToPOS,
    },
    reservation: {
      condition:
        user?.venue?.configurations?.isReservation &&
        user.permissions?.canSeeReservationCalendar,
      path: "/dashboard/reservation/calendar",
      action: handleGoToReservations,
    },
    tables: {
      condition:
        user?.venue?.configurations?.isTableFeatureAvailed &&
        user.permissions?.canEditOrderOnPOS,
      path: "/dashboard/tables",
      action: handleGoToTablesScreen,
    },
    orders: {
      condition:
        user?.venue?.configurations?.isOrderingFeatureAvailed &&
        user?.permissions?.canSeeOrderProcessingScreen,
      path: "/dashboard/orders",
      action: handleGoToOrders,
    },
    kitchen: {
      condition:
        user?.venue?.configurations?.isKitchenFeatureAvailed &&
        user?.permissions?.canSeeKitchenScreen,
      path: "/dashboard/kitchen",
      action: handleGoToKitchenScreen,
    },
  };

  useEffect(() => {
    if (
      [
        "/dashboard/pos",
        "/dashboard/reservation/calendar",
        "/dashboard/tables",
        "/dashboard/orders",
        "/dashboard/kitchen",
      ].includes(pathname)
    ) {
     
      const availableTabs = Object.entries(tabMappings)
        .filter(([_, { condition, path }]) => condition && pathname === path)
        .map(([key]) => key);
  
      if (!availableTabs.length) {
        availableTabs.push(
          ...Object.keys(tabMappings).filter((key) => tabMappings[key].condition)
        );
      }
      if (availableTabs.length > 0) {
        tabMappings[availableTabs[0]].action();
      }
    }
  }, [pathname, user]);

  const tabs = [
    {
      label: "POS",
      permission: "canViewPos",
      configuration: "isPOSAvailable",
      onClick: handleGoToPOS,
    },
    {
      label: "Reservation",
      permission: "canSeeReservationCalendar",
      configuration: "isReservation",
      onClick: handleGoToReservations,
    },
    {
      label: "Tables",
      permission: "canEditOrderOnPOS",
      configuration: "isTableFeatureAvailed",
      onClick: handleGoToTablesScreen,
    },
    {
      label: "Orders",
      permission: "canSeeOrderProcessingScreen",
      configuration: "isOrderingFeatureAvailed",
      onClick: handleGoToOrders,
      orderCount: (
        <span>
          {user?.venue?.configurations?.isPickUp || user?.venue?.configurations?.isDineIn ? (
            <span>
              {orderCount.pendingOrders} / {orderCount.processingOrders} /{" "}
              {orderCount.finishedOrders}
            </span>
          ) : (
            <span>
              {orderCount.processingOrders} / {orderCount.finishedOrders}
            </span>
          )}
        </span>
      ),
    },
    {
      label: "Kitchen",
      permission: "canSeeKitchenScreen",
      configuration: "isKitchenFeatureAvailed",
      onClick: handleGoToKitchenScreen,
      orderCount: (
        <span>
          {" "}
          {orderCount.processingOrders && orderCount.processingOrders}{" "}
        </span>
      ),
    },
  ];

  const isActiveTab = (tab) => {
    switch (tab.label) {
      case "POS":
        return isPOS();
      case "Reservation":
        return isCalendar();
      case "Tables":
        return isTables();
      case "Orders":
        return isOrders();
      case "Kitchen":
        return isKitchenScreen();
      default:
        return false;
    }
  };

  const calculateRightProperty = (totalTabsCount) => {
    switch (totalTabsCount) {
      case 5:
        return 40;
      case 4:
        return 30;
      case 3:
        return 20;
      case 2:
        return 10;
      case 1:
        return 0;
      default:
        return 0;
    }
  };

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      {(isPOS() ||
        isCalendar() ||
        isTables() ||
        isCalendar() ||
        isOrders() ||
        isKitchenScreen()) && (
          <Stack
            flexGrow={1}
            zIndex={100}
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={{ xs: 0, sm: 0 }}
            marginRight={2}
          >
            {tabs
              .filter((tab) => {
                const condition = user?.venue?.configurations[tab.configuration];
                const permission = user.permissions?.[tab.permission];
                return condition && permission;
              })
              .map((tab, index) => (
                <Button
                  key={index}
                  size="medium"
                  type="submit"
                  variant={isActiveTab(tab) ? "contained" : "outlined"}
                  onClick={tab.onClick}
                  sx={{
                    width: "100%",
                    borderRadius: "20px 20px 0px 0px",
                    bgcolor: !isActiveTab(tab) ? "white" : "",
                    zIndex: isActiveTab(tab)
                      ? tabs.length
                      : tabs.length - index - 1,
                    right:
                      calculateRightProperty(tabs.length) -
                      (tabs.length - index - 1) * 10,
                    '@media (min-width: 768px) and (max-width: 1024px)': {
                      width: tab.label == 'Reservation' ? '160%' : '100%', // Color between 768px and 1024px
                    },
                  }}
                >
                  {tab.label}
                  {
                    tab.label == 'Reservation' ?
                      <Label
                        style={{
                          textTransform: 'lowercase',
                          position: 'relative',
                          left: '10px',
                          backgroundColor: '#f8ecd6',
                          color: '#b76e00'
                        }}>beta</Label>
                      : null
                  }
                  {tab.orderCount && (
                    <span
                      style={{
                        marginLeft: "2px",
                      }}
                    >
                      ({tab?.orderCount})
                    </span>
                  )}
                </Button>
              ))}
          </Stack>
        )}

      <Stack
        flexGrow={
          isPOS() ||
            isTables() ||
            isCalendar() ||
            isOrders() ||
            isKitchenScreen()
            ? ""
            : 1
        }
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <LevelPopover onLevelChange={handleLevelChange} />
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
