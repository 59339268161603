import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { Box, Stack } from '@mui/material';
//
import NavList from './NavList';
import { useAuthContext } from 'src/auth/useAuthContext';
import useVenueAndMarketplaceConfig from 'src/hooks/useVenueAndMarketplaceConfig';

// ----------------------------------------------------------------------

NavSectionMini.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

function NavSectionMini({ data, sx, ...other }) {

  const { user } = useAuthContext();
  let checkVenueMarketplace = useVenueAndMarketplaceConfig(['isPOSAvailable', 'isDineIn','isPickUp','isQrTableScanAvailable','isQrVenueScanAvailable','isQrCarHopScanAvailable'])
  let filteredData = data.map((group) => {
    let items = group.items;

    if (!user?.venue?.configurations?.isTableFeatureAvailed ||  !user?.permissions.canViewTablesTab ) {
      items = items.filter((item) => item.title !== "tables");
    }
    if (!user?.permissions?.canViewDashboard) {
      items = items.filter((item) => item.title !== "dashboard");
    }
    if (!user?.permissions?.canViewStaff) {
      items = items.filter((item) => item.title !== "staff");
    }
    if (!user?.permissions?.canAccessReports) {
      items = items.filter((item) => item.title !== "report");
    }

    if (
      (!user?.venue?.configurations?.isReservation &&
        !user?.venue?.configurations?.isTableFeatureAvailed &&
        !user?.venue?.configurations?.isOrderingFeatureAvailed &&
        !user?.venue?.configurations?.isKitchenFeatureAvailed &&
        !user?.venue?.configurations?.isPOSAvailable) ||
      (!user?.permissions?.canViewPos &&
        !user?.permissions?.canSeeReservationCalendar &&
        !user?.permissions?.canEditOrderOnPOS && 
        !user?.permissions?.canSeeOrderProcessingScreen &&
        !user?.permissions?.canSeeKitchenScreen)
    ) {
      items = items.filter((item) => item.title !== "terminal");
    }
    items = items.map((item) => {
      if (item.title === "terminal") {
        if (
          user?.venue?.configurations?.isPOSAvailable &&
          user?.permissions?.canViewPos
        ) {
          item.path = "/dashboard/pos";
        } else if (
          user?.venue?.configurations?.isReservation &&
          user?.permissions?.canSeeReservationCalendar
        ) {
          item.path = "/dashboard/reservation/calendar";
        } else if (
          user?.venue?.configurations?.isTableFeatureAvailed &&
          user?.permissions?.canEditOrderOnPOS
        ) {
          item.path = "/dashboard/tables";
        } else if (
          user?.venue?.configurations?.isOrderingFeatureAvailed &&
          user?.permissions?.canSeeOrderProcessingScreen
        ) {
          item.path = "/dashboard/orders";
        } else if (
          user?.venue?.configurations?.isKitchenFeatureAvailed &&
          user?.permissions?.canSeeKitchenScreen
        ) {
          item.path = "/dashboard/kitchen";
        }
      }
      return item;
    });

    if (!user?.venue?.configurations?.isInventoryAvailable ||  !user?.permissions.canViewInventoryTab) {
      items = items.filter((item) => item.title !== "inventory"  );
    }

    if (!user?.permissions?.canViewVenueCustomers) {
      items = items.filter((item) => item.title !== "customers");
    }
    if (!user?.permissions?.canViewLedger ||
      (!checkVenueMarketplace)) {
      items = items.filter((item) => item.title !== "Ledger");
    }
    if(!checkVenueMarketplace) {
      items = items.filter((item) => item.title !== "menu");
    }
    if(!checkVenueMarketplace) {
      items = items.filter((item) => item.title !== "orders");
    }
    if (!user?.venue?.configurations?.isPromotionAvailable) {
      items = items.filter((item) => item.title !== "promotions");
    }

    return { ...group, items };
  });
  return (
    <Stack
      spacing={0.5}
      alignItems="center"
      sx={{
        px: 0.75,
        ...sx,
      }}
      {...other}
    >
      {filteredData.map((group, index) => (
        <Items key={group.subheader} items={group.items} isLastGroup={index + 1 === data.length} />
      ))}
    </Stack>
  );
}

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

Items.propTypes = {
  items: PropTypes.array,
  isLastGroup: PropTypes.bool,
};

function Items({ items, isLastGroup }) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
      ))}

      {!isLastGroup && (
        <Box
          sx={{
            width: 24,
            height: '1px',
            bgcolor: 'divider',
            my: '8px !important',
          }}
        />
      )}
    </>
  );
}
