import React from 'react';
import { Navigate } from 'react-router-dom';
import useVenueAndMarketplaceConfig from 'src/hooks/useVenueAndMarketplaceConfig';

const PrivateRouteVenueConfig = ({ component: Component,requiredFeatures, ...rest }) => {
  const hasAccess = useVenueAndMarketplaceConfig(requiredFeatures);

  if (!hasAccess) {
    return <Navigate to='/404' replace />;
  }
  return <Component {...rest} />;
};

export default PrivateRouteVenueConfig;