import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isLoading: false,
    error: null,
    isConnected: false,
    message: "",
};

const slice = createSlice({
    name: "fbr",
    initialState,
    reducers: {
        requestStarted: (state) => {
            state.isLoading = true;
            state.message = "";
        },
        connectionSuccess: (state) => {
            state.isLoading = false;
            state.isConnected = true;
            state.message = "Connected to FBR successfully.";
        },
        connectionFailed: (state, action) => {
            state.isLoading = false;
            state.isConnected = false;
            state.error = action.payload;
            state.message = "Failed to connect to FBR.";
        },
    },
});

export default slice.reducer;

export const checkFBRConnection = (ipAddress, isFbrIntegrationAvailable) => async (dispatch) => {
    if (!isFbrIntegrationAvailable) return

    dispatch(slice.actions.requestStarted());
    try {
        const response = await axios.get(`http://${ipAddress}:8524/api/IMSFiscal/Get`);
        if (response.status === 200) {
            dispatch(slice.actions.connectionSuccess());
        } else {
            throw new Error("Unexpected response from FBR");
        }
    } catch (error) {
        const errorMsg = error.response?.data?.message || error.message || "Error connecting to FBR";
        dispatch(slice.actions.connectionFailed(errorMsg));
    }
};

export const sendDataToFbr = (ipAdressForFbr, invoice) => async (dispatch) => {
    dispatch(slice.actions.requestStarted());
    try {
        const response = await axios.post(`http://${ipAdressForFbr}:8524/api/IMSFiscal/GetInvoiceNumberByModel`, invoice);
        return { data: response?.data };
    } catch (error) {
        const errorMsg = error.response?.data?.message || error.message || "Error connecting to FBR";
        dispatch(slice.actions.connectionFailed(errorMsg));
    }
};
