import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import productReducer from "./slices/product";
import categoryReducer from "./slices/category";
import statReducer from "./slices/stats";
import cartReducer from "./slices/cart";
import itemReducer from "./slices/item";
import choiceGroupReducer from "./slices/choiceGroup";
import menuReducer from "./slices/menu";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import areaReducer from "./slices/area";
import desiginationReducer from "./slices/desigination";
import venueUserReducer from "./slices/venueUser";
import venueReducer from "./slices/venue";
import tableReducer from "./slices/table";
import kitchenSlice from "./slices/kitchenSlice";
import micsExpense from "./slices/micsExpense";
import customer from "./slices/customer";
import ingredientReducer from "./slices/ingredient";
import RecipeSlice from "./slices/recipe"
import gallery from "./slices/gallery";
import ordersSlice from "./slices/ordersSlice";
import stockSlice from './slices/stock'
import adjustmentSlice from './slices/adjustment'
import orderHandling from './slices/orderHandlingSlice'
import menuTypeSlice from './slices/menuTypeSlice'
import promotionsSlice from "./slices/promotions";
import fbr from './slices/fbr'

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

export const cateogoryPersistConfig = {
  key: "category",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const statPersistConfig = {
  key: "stat",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const cartPersistConfig = {
  key: "posCart",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

// export const itemPersistConfig = {
//   key: "item",
//   storage,
//   keyPrefix: "redux-",
//   whitelist: [],
// };

export const choiceGroupPersistConfig = {
  key: "choiceGroup",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const menuPersistConfig = {
  key: "menu",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const areaPersistConfig = {
  key: "area",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const desiginationPersistConfig = {
  key: "desigination",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const venueUserPersistConfig = {
  key: "venueUser",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const venuePersistConfig = {
  key: "venue",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const tablePersistConfig = {
  key: "table",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const orderHandlingPersistConfig = {
  key: "orderHandling",
  storage,
  keyPrefix: "redux-",
  whitelist: ["isPayLoading", "orderCartId","responseTime"],
};
export const menuTypePersistConfig = {
  key: "menuTypeSlice",
  storage,
  keyPrefix: "redux-",
  whitelist: ["menuType"],
};


const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  kitchenSlice: kitchenSlice,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  category: persistReducer(cateogoryPersistConfig, categoryReducer),
  stat: persistReducer(statPersistConfig, statReducer),
  area: persistReducer(areaPersistConfig, areaReducer),
  desigination: persistReducer(desiginationPersistConfig, desiginationReducer),
  venueUser: persistReducer(venueUserPersistConfig, venueUserReducer),
  venue: persistReducer(venuePersistConfig, venueReducer),
  table: persistReducer(tablePersistConfig, tableReducer),
  // item: persistReducer(itemPersistConfig, itemReducer),
  item:itemReducer,
  choiceGroup: persistReducer(choiceGroupPersistConfig, choiceGroupReducer),
  menu: persistReducer(menuPersistConfig, menuReducer),
  posCart: persistReducer(cartPersistConfig, cartReducer),
  micsExpense: micsExpense,
  customer:customer,
  ingredient:ingredientReducer,
  recipe:RecipeSlice,
  gallery: gallery,
  ordersSlice: ordersSlice,
  stockSlice:stockSlice,
  adjustmentSlice:adjustmentSlice,
  orderHandlingSlice: persistReducer(orderHandlingPersistConfig, orderHandling),
  menuTypeSlice: persistReducer(menuTypePersistConfig, menuTypeSlice),
  promotions: promotionsSlice,
  fbr:fbr

});


export default rootReducer;
